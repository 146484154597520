import React, { useEffect, useState }  from 'react';
import Banner from '../components/menu/CompMenuBanner';
import CompMenuActos from '../components/menu/CompMenuActos';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import menumyc from '../assets/menus/menumyc.png';
import menu2 from '../assets/menus/menu2.jpg';
import menu3 from '../assets/menus/menu3.jpg';
import menu4 from '../assets/menus/menu4.jpg';
import menu5 from '../assets/menus/menu5.jpg';
import menu6 from '../assets/menus/menu6.png';
import menu7 from '../assets/menus/menu7.png';



function Home() {
  
  useEffect(() => {
    window.scrollTo(0, 0); // Forzar el desplazamiento al inicio de la página
  }, []); // [] asegura que esto solo se ejecute una vez cuando el componente se monta

  // Estado Comparsa persistente (de localStorage)
  const [selectedId] = useState(localStorage.getItem('selectedComparsa') || '0');
  
  // Se reestablece estado para la selección temporal
  localStorage.setItem('selectedIdTemporal', selectedId);

  console.log('Valor de posicionActoTemp en localStorage:', localStorage.getItem('posicionActoTemp'));
  console.log('Valor de posicionTemp en localStorage:', localStorage.getItem('posicionTemp'));
  localStorage.setItem('posicionActoTemp', 0); // Restablecer al cargar Home
  localStorage.setItem('posicionTemp', 0); // Restablecer al cargar Home
  console.log('Nuevo valor de posicionActoTemp en localStorage:', localStorage.getItem('posicionActoTemp'));
  console.log('Nuevo valor de posicionTemp en localStorage:', localStorage.getItem('posicionTemp'));
  

  const navigate = useNavigate();
  useEffect(() => {
    // Verificar si es la primera sesión en localStorage
    const primeraSesion = localStorage.getItem('primeraSesion');

    if (!primeraSesion) {
      // Si no existe, es la primera vez que el usuario entra
      localStorage.setItem('primeraSesion', 'false'); // Establecer que ya no es la primera sesión
      navigate('/ajustes'); // Redirigir a la ruta de ajustes
    }
  }, [navigate]);


  return (
    <div>

{/*       <Minibanner />
      <Relleno /> */}
      <div className="home">

        {/* HEADER APP */}
        <div className="image-header">
          <img src={menumyc} alt="menuHeader" />
        </div>

        {/* FILAS Y COMPARSAS */}
        <Link to="/comparsas" style={{ textDecoration: 'none' }} className="filascomparsas">
          <div className="image-container">
            <img src={menu2} alt="FilasComparsas" />
            <div className="element-text element-text-fc">Filas y Comparsas</div>
          </div>
        </Link>

        <div className="row">
              {/* BANNER */}
              <Banner />
              
              {/* ÓRDENES */}
              <Link to="/ordenes" style={{ textDecoration: 'none' }} className="ordenes">
                <div>
                  <img src={menu3} alt="Órdenes" />
                  <div className="element-text">Órdenes</div>
                </div>
              </Link>
        </div>

        {/* ACTOS */}
        <CompMenuActos />

        {/* LUGARES */}
        <Link to="/lugares-interes" style={{ textDecoration: 'none' }} className="lugares">
          <div className="image-container">
            <img src={menu4} alt="Lugares" />
            <div className="element-text">Lugares de Interés</div>
          </div>
        </Link>

        {/* COMERCIOS */}
        <Link to="/comercio-tipos" style={{ textDecoration: 'none' }} className="comercios">
          <div className="image-container">
            <img src={menu5} alt="Comercios" />
            <div className="element-text">Comercios</div>
          </div>
        </Link>

        <div className="row">
              {/* AJUSTES */}
              <Link to="/ajustes" style={{ textDecoration: 'none' }} className="ajustes">
                <div>
                  <img src={menu6} alt="Ajustes" />
                  <div className="element-text">Ajustes</div>
                </div>
              </Link>

              {/* ACERCA DE */}
              <Link to="/acerca-de" style={{ textDecoration: 'none' }} className="acerca-de">
                <div>
                  <img src={menu7} alt="Acerca De" />
                  <div className="element-text">Acerca de...</div>
                </div>
              </Link>
        </div>

      </div>
    </div>
  );
}

export default Home;